@media only screen and (orientation: landscape) and (min-width: 900px) {
    .post {
        max-width: 900px !important;
    }
}

.post {
    margin: auto;
    max-width: 90%;
    padding: 1.325;
    width: 42em;
}

.post-title h1 {
    margin: 0;
}

.post-description {
    margin-bottom: 0.5em;
}

.post-date {
    color: $secondary-highlight;
    font-weight: bold;
    font-size: 0.9rem;
}

.post-image {
    max-width: 100%;
    max-height: 600px;
    border-radius: 5px;
    margin-top: 1em;
}

.post-category { 
    font-weight: bold;
    font-size: 0.9rem;
    color: $primary-highlight;
    &:hover, &:focus {
        color: lighten($primary-highlight, 14%);
    }
}

.post-content {
    margin-bottom: 2em;
}

.clickable-image {
    max-width: 100%;
    max-height: 600px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.clickable-image a {
    max-width: 80%;
    display: inline-block;
}

.post-content img {
    max-width: 100%;
    max-height: 600px;
    border-radius: 5px;
}