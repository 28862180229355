// Colours

$primary-highlight: #f2777a;
$secondary-highlight: #6b7fd7;
$tertiary-highlight: #66cccc;

$sidebar-colour: #1a2a38;
$hover-colour: #e5e5e5;
$description-colour: #474747;
$text-colour-light: white;
$text-colour-dark: black;

$table-background-colour: #e5e5e5;

// Sizes

$sidebar-width: 330px;
$sidebar-height-min: 150px;
$sidebar-height: 200px;