@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
}

  @font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/open-sans/OpenSans-Italic.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/open-sans/OpenSans-BoldItalic.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/roboto-mono/RobotoMono-Regular.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/roboto-mono/RobotoMono-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/roboto-mono/RobotoMono-Italic.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/roboto-mono/RobotoMono-BoldItalic.ttf') format('truetype');
}