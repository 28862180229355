.list-posts {
    margin-bottom: 2em;
}

.list-post-link {
    margin: 2em 1em 0 1em;
    display: block;
    width: auto;
    height: auto;
}

.list-post-link:hover {
    background-color: $hover-colour;
    opacity: 0.8;
    border-radius: 5px;
    transition: .2s ease-in-out;
    -webkit-transition: .2s ease-in-out;
}

.list-post-image {
    width: 100%;
    margin-bottom: 0.4em;
    border-radius: 5px;
}

.list-post-info {
    margin: 0 0.4em;
}

.list-post-title {
    color: $text-colour-dark;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    a {
        color: white;
        &:hover {
            color: white;
        }
    }
}

.list-post-description {
    color: $description-colour;
    margin: 0.4em 0;
    font-size: 1rem;
}

.list-post-footer {
    margin-left: 1.4em;
}

.list-post-date {
    color: $secondary-highlight;
    font-weight: bold;
    font-size: 0.9rem;
}

.list-post-category { 
    font-weight: bold;
    font-size: 0.9rem;
    color: $primary-highlight;
    &:hover, &:focus {
        color: lighten($primary-highlight, 14%);
    }
}

.list-post-tags {
    font-weight: bold;
    font-size: 0.9rem;
    color: $tertiary-highlight;
    margin-top: 0.4em;
}